<template lang="pug">
  //-統一跟一般課程、隨選隨上、百家書苑API回傳格式
  div
    server-time-clock
    b-alert.text-center(show variant='warning') {{ $t('bookAcademyTalksNotice') }}
    template
      .reg-classroom
        vip-classroom(:show_consultant_img='true' :classroom_type="'academyTalks'")
      b-pagination-nav(base-url='javascript:;' align='center' number-of-pages='1')
    template
      .reg-no-booking
        span(v-html="$t('noAcademyTalksMessage')")
</template>

<script type="text/javascript">
import ServerTimeClock from '@/components/serverTimeClock';
import VipClassroom from '@/components/class/vipClassroom';
import i18n from './lang';

export default {
  name: 'AcademyTalks',

  components: {
    ServerTimeClock,
    VipClassroom,
  },

  data() {
    return {
    };
  },

  i18n,

};
</script>

<style lang="scss">
@import "@/styles/class/classroom";
</style>
