var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('server-time-clock'), _c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(_vm._s(_vm.$t('bookAcademyTalksNotice')))]), [_c('div', {
    staticClass: "reg-classroom"
  }, [_c('vip-classroom', {
    attrs: {
      "show_consultant_img": true,
      "classroom_type": 'academyTalks'
    }
  })], 1), _c('b-pagination-nav', {
    attrs: {
      "base-url": "javascript:;",
      "align": "center",
      "number-of-pages": "1"
    }
  })], [_c('div', {
    staticClass: "reg-no-booking"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('noAcademyTalksMessage'))
    }
  })])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }