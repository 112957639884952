export default {
  messages: {

    en: {
      bookAcademyTalksNotice: 'Remind you that Baijia Academy is a one-way lecture course, not an interactive course! After the reservation is complete, please enter the classroom 2 minutes before the class starts.',
      noAcademyTalksMessage: 'There is currently no Baijia Academy available for booking, please check again later',
    },

    tw: {
      bookAcademyTalksNotice: '提醒您，百家書院為單向式講座課程，而非互動式課程唷！預約完成後，請於課程開始前2分鐘進入教室。',
      noAcademyTalksMessage: '目前並無開設的百家書苑可供訂課，請稍後再進行查詢',
    },

  },
};
